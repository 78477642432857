<template>
  <div class="swiper-container">
    <!-- swiperOptions  配置轮播插件的参数-->
    <swiper ref="mySwiper" :options="swiperOption" class="swiper">
      <!-- slides -->
      <swiper-slide v-for="(item, index) in swiperImages" :key="index">
        <a :href="item.url" target="_blank">
          <img :src="item.img" class="swiperImg" />
        </a>
      </swiper-slide>
      <!-- Optional controls -->
      <!-- 分页器 -->
      <div class="swiper-pagination" slot="pagination"></div>
      <!-- 左箭头-->
      <div
        class="swiper-button-prev swiper-button-white"
        slot="button-prev"
      ></div>
      <!-- 右箭头 -->
      <div
        class="swiper-button-next swiper-button-white"
        slot="button-next"
      ></div>
    </swiper>
  </div>
</template>

<script>
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import 'swiper/swiper-bundle.css'
  export default {
    name: "storeSwiper",
    components: {
      swiper,
      swiperSlide
    },
    props: {
      swiperImages: {
        type: Array 
      },
    },
    data() {
      return {
        //配置轮播插件的参数
        swiperOption: {
          loop: true, //循环轮播
          spaceBetween: 30,
          grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状 
          autoplay: {
            delay: 2500,
            disableOnInteraction: false
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        }
      }
    }

  }
</script>

<style lang="less" scoped>
.swiper-container {
  width: 1200px;
  position: relative;
  margin: 0 auto;

  .swiper-pagination {
    position: absolute;
    left: 50%;
    bottom: 2%;
    transform: translate(-50%);
    --swiper-pagination-color: #fff;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #fff;
  }

  /deep/ .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
  .swiperImg {
    width: 1200px;
    height: 400px;
  }
}
</style>